

@font-face {
  font-family: "UI Sans";
  src: url(/fonts/UI_Sans_v10_Light.woff2) format("woff2");
  /* font-style: normal; */
  font-weight: 200;
  font-display: swap;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "UI Sans";
  src: url(/fonts/UI_Sans_v10_Regular.woff2) format("woff2");
  /* font-style: normal; */
  font-weight: 400;
  font-display: swap;
  text-rendering: optimizeLegibility;
}

@font-face { 
  font-family: "UI Sans";
  src: url(/fonts/UI_Sans_v10_Bold.woff2) format("woff2");
  /* font-style: normal; */
  font-weight: 700;
  font-display: swap;
  text-rendering: optimizeLegibility;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  min-height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

/* bug fix for next/image */
@media not all and (min-resolution: 0.001dpcm) {
  img[loading="lazy"] {
    -webkit-clip-path: inset(0.5px);
    clip-path: inset(0.5px);
  }
}

/* hide Fusion Charts credits */
g[class^="raphael-group-"][class$="-creditgroup"] {
  display: none !important;
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #2563eb;
}

::selection {
  color: white;
  background: #2563eb;
}

.ag-root-wrapper {
  border: 0 !important;
  font-family: "UI Sans", sans-serif;
}
.ag-header {
  z-index: 1;
  background-color: #f3f4f6;
  border-bottom: 1px solid #e5e7eb !important;
  box-shadow: 0 4px 6px 0px rgb(0 0 0 / 0.1), 0 2px 4px 0px rgb(0 0 0 / 0.1);
}

.cell-wrap-text {
  white-space: normal !important;
}

